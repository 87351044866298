import { TDataFrequency } from '@/api/calculators/calculators.types';
import { TTransformedDataFrequency } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';

export const mapDataDefaultsFrequencyToTransformedDataFrequency = (
  data: TDataFrequency,
): TTransformedDataFrequency[] => {
  return Object.entries(data).map(([key, value]) => [key, +value.toFixed(2)]);
};

export const mapDataLossFrequencyToTransformedDataFrequency = (data: TDataFrequency): TTransformedDataFrequency[] => {
  return Object.entries(data).map(([key, value]) => {
    const formattedKey = key.split('_').join('-') + '%';

    return [formattedKey, +value.toFixed(2)];
  });
};
