import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from '@mui/material';
import styled from '@emotion/styled';
import Tooltip from '@/components/atoms/Tooltip/Tooltip';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import AgioLogo from '@/assets/icons/sidebar-logo.svg';
import NotificationIcon from '@/assets/icons/notifications.svg?react';
import DocumentationIcon from '@/assets/icons/documentation.svg?react';
import CalculatorIcon from '@/assets/icons/calculator.svg?react';
import ChartIcon from '@/assets/icons/chart.svg?react';
import SettingsIcon from '@/assets/icons/settings.svg?react';
import LogoutIcon from '@/assets/icons/logout.svg?react';
import MonitoringIcon from '@/assets/icons/monitoring.svg?react';

import { useTracking } from '@/hooks/useTracking';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { useGetDocumentation } from '@/api/documentation/documentation.hooks';
import { EAdminPaths, ERoutes } from '@/types/routes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  height: 100vh;
  flex-shrink: 0;
  background-color: #101014;
  border-right: 1px solid #2e2e31;
  z-index: 1;
`;

const NavBarWrapper = styled.div`
  height: auto;
  width: 56px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #2e2e31;
  flex-direction: column;
  align-items: center;

  & > div {
    margin: 20px 0;
  }
`;

const LogoWrapper = styled.div`
  margin-block: 45px;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;

const WrapperIcons = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  height: 24px;
  width: 24px;
  justify-content: center;

  &:hover {
    cursor: pointer;
    border-radius: 8px;
    background: #1b1b1e;
  }
`;

const StyledIcon = styled.div<{ isHovered?: boolean }>`
  width: 100%;
  height: 100%;
  --stroke-color: ${(props) => (props.isHovered ? '#5d51b8' : 'white')};
`;

const StyledAdminIcon = styled(AdminPanelSettingsOutlinedIcon)`
  color: #fff;

  &:hover {
    color: #5d51b8;
    cursor: pointer;
  }
`;

interface IconProps {
  Icon: React.ElementType;
  onClick?: () => void;
  tooltip: string;
}

const IconWithHover = ({ Icon, onClick, tooltip }: IconProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Tooltip title={tooltip}>
      <WrapperIcons onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <StyledIcon isHovered={isHovered}>
          <Icon />
        </StyledIcon>
      </WrapperIcons>
    </Tooltip>
  );
};

const SideBar = () => {
  const navigate = useNavigate();
  const { logOut } = useNewAuth();
  const { trackEvent } = useTracking();
  const { shouldDisplayDocumentation, shouldDisplayAdminPanel } = usePermissionsManager();
  const { fetchDocumentation } = useGetDocumentation();

  const handleOnClick = (path: string) => {
    navigate(path);
  };

  const handleWhitepaperClick = () => {
    trackEvent('Whitepaper Clicked', {});
    fetchDocumentation(undefined, {
      onSuccess: ({ url }) => {
        window.open(url, '_blank');
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <Container>
      <LogoWrapper>
        <Link href={ERoutes.ALERTS} underline="none">
          <img src={AgioLogo} alt="Agio logo" />
        </Link>
      </LogoWrapper>
      <NavBarWrapper>
        <IconWithHover onClick={() => handleOnClick(ERoutes.RATINGS)} Icon={ChartIcon} tooltip="Ratings" />
        <IconWithHover onClick={() => handleOnClick(ERoutes.ALERTS)} Icon={NotificationIcon} tooltip="Alerts" />
        {/*<IconWithHover*/}
        {/*  onClick={() => handleOnClick(ERoutes.LOSS_CALCULATOR)}*/}
        {/*  Icon={CalculatorIcon}*/}
        {/*  tooltip="Loss risk calculator"*/}
        {/*/>*/}
        <IconWithHover
          onClick={() => handleOnClick(ERoutes.RISK_SIMULATOR)}
          Icon={CalculatorIcon}
          tooltip="Risk simulator"
        />

        <IconWithHover onClick={() => handleOnClick(ERoutes.MONITORING)} Icon={MonitoringIcon} tooltip="Monitoring" />

        {shouldDisplayDocumentation && (
          <IconWithHover onClick={handleWhitepaperClick} Icon={DocumentationIcon} tooltip="Documentation" />
        )}
      </NavBarWrapper>
      <Wrapper>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '45px', gap: '20px' }}>
          <IconWithHover onClick={() => handleOnClick(ERoutes.SETTINGS)} Icon={SettingsIcon} tooltip="Settings" />

          {shouldDisplayAdminPanel && (
            <StyledAdminIcon onClick={() => handleOnClick(`${ERoutes.ADMIN}/${EAdminPaths.ALERTS}`)} />
          )}
        </div>
        <IconWithHover onClick={logOut} Icon={LogoutIcon} tooltip="Logout" />
      </Wrapper>
    </Container>
  );
};

export default SideBar;
