import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ThemeProvider } from '@mui/material/styles';
import AppRoutes from '@/routes/AppRoutes';
import LoginPage from '@/pages/LoginPage';
import { theme } from '@/theme';
import { NewAuthProvider } from '@/providers/AuthProvider/NewAuthProvider';
import { ApiProvider } from '@/providers/ApiContextProvider/ApiContextProvider';
import { AlertsFiltersProvider } from '@/providers/AlertsFiltersContextProvider/AlertsFiltersContextProvider';
import { EntitiesListProvider } from '@/providers/EntitiesListContextProvider/EntitiesListContextProvider';
import { RatingsProvider } from '@/providers/RatingsListContextProvider/RatingsListContextProvider';
import { NewFeatureProvider } from '@/providers/NewFeatureContextProvider/NewFeatureContextProvider';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <NewAuthProvider>
      <ApiProvider>
        <RatingsProvider>
          <EntitiesListProvider>
            <AlertsFiltersProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={theme}>
                  <Suspense fallback={<LoginPage />}>
                    <BrowserRouter basename={import.meta.env.VITE_APP_BASE_URL}>
                      <NewFeatureProvider>
                        <AppRoutes />
                      </NewFeatureProvider>
                    </BrowserRouter>
                  </Suspense>
                </ThemeProvider>
              </LocalizationProvider>
            </AlertsFiltersProvider>
          </EntitiesListProvider>
        </RatingsProvider>
      </ApiProvider>
    </NewAuthProvider>
  </QueryClientProvider>
);

export default App;
