import { z, ZodType } from 'zod';

export interface IMonitoringForm {
  entities: string[];
  sortedEntities: string[];
}

export const MonitoringSchema: ZodType<IMonitoringForm> = z.object({
  entities: z.string().array().min(1, { message: 'Select at least 1 entity to monitor.' }),
  sortedEntities: z.string().array(),
});
