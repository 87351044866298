import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { DISABLE_CHART_EXPORTING_MENU, RESET_ZOOM_BUTTON_POSITION } from '@/constants/chartOptions';
import { adjustSeverityChartColor, adjustSeverityChartLabel } from '@/helpers/severityLevelMethods';
import { useAdjustSeverityZones } from '@/components/molecules/Charts/useAdjustSeverityZones';
import { ChartData } from '@/types/chartPoint';

const Figure = styled.figure`
  width: 100%;
  margin: 1em auto;
`;

interface TooltipFormatterContextObject {
  x: number;
  y: number;
  point: {
    series: {
      name: string;
    };
  };
}

interface ISeverityTrendingChartProps {
  chartData: ChartData;
  title?: string;
}

export const SeverityTrendingChart = ({ chartData, title }: ISeverityTrendingChartProps) => {
  const backgroundColor = '#101014';
  const chartBackground = '#282829';

  chartData.sort((a, b) => a.x - b.x);

  const timestampOfLastDataPoint = chartData?.length ? chartData[chartData?.length - 1].x : null;
  const timestampOfFirstDataPoint = chartData?.length ? chartData[0].x : null;

  let chartSubtitle;

  if (timestampOfLastDataPoint && timestampOfFirstDataPoint) {
    const initialDate = format(new Date(timestampOfFirstDataPoint), 'MMMM dd, yyyy');
    const finalDate = format(new Date(timestampOfLastDataPoint), 'MMMM dd, yyyy');
    chartSubtitle = `Latest anomalies gathered: ${initialDate} - ${finalDate}`;
  }

  const allSeverityNumbers = [...chartData]
    .map((data) => {
      return data.y;
    })
    .filter((d): d is number => d !== null);

  const { zones } = useAdjustSeverityZones({ allSeverities: allSeverityNumbers });

  const chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: backgroundColor,
      height: 243,
      type: 'area',
      zoomType: 'x',
      ...RESET_ZOOM_BUTTON_POSITION,
    },
    title: {
      text: title || undefined,
      align: 'right',
      style: {
        color: '#fff',
        fontSize: '16px',
      },
    },
    subtitle: {
      text: chartSubtitle,
      style: {
        color: '#F7F7F7',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '22.584px',
      },
      align: 'right',
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      maxPadding: 0,
      gridLineWidth: 1,
      gridLineColor: chartBackground,
      max: timestampOfLastDataPoint,
      min: timestampOfFirstDataPoint,
      type: 'datetime',
      tickLength: 0,
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      tickInterval: 3600 * 3000, // three hours,
      labels: {
        style: {
          color: '#F7F7F7',
          fontSize: '14px',
          textOverflow: 'none',
        },
        formatter: function (this: { value: number }) {
          const getProperHours = new Date(this.value).getHours();
          const getProperMinutes = new Date(this.value).getMinutes();
          const hoursPrefix = getProperHours < 10 ? '0' : '';
          const minutesPrefix = getProperMinutes < 10 ? '0' : '';
          return `${hoursPrefix}${getProperHours}:${minutesPrefix}${getProperMinutes}`;
        },
      },
    },
    yAxis: {
      gridLineColor: chartBackground,
      title: {
        text: undefined,
      },
      min: -0.5,
      max: 4.5,
      tickInterval: 1,
      labels: {
        formatter: function (this: { value: number }): string {
          return adjustSeverityChartLabel(this.value).toUpperCase();
        },
        style: {
          color: '#F7F7F7',
          lineHeight: '15.809px',
        },
      },
    },
    tooltip: {
      backgroundColor: '#1B1B1E',
      borderRadius: 8,
      style: {
        color: '#ffffff',
      },
      useHTML: true,
      formatter: function (this: TooltipFormatterContextObject) {
        const date = format(this.x, 'dd MMM - kk:mm');
        let s = `<span style="color: #fff;">${date}</span>`;
        const value = adjustSeverityChartLabel(this.y).toUpperCase();
        const color = adjustSeverityChartColor(this.y);
        s += `<div style="display:inline-block; width:10px; height:10px; background-color:${color}; border-radius:5px; margin:0 5px;"></div> <span style="color: #fff;">${value}</span>`;
        return s;
      },
    },
    plotOptions: {
      series: {
        pointPlacement: 'on',
      },
      area: {
        marker: {
          radius: 1,
        },
        states: {
          hover: {
            lineWidth: 5,
          },
        },
      },
    },
    series: [
      {
        type: 'area',
        name: 'Trending Risk',
        data: chartData,
        lineWidth: 5,
        marker: {
          enabled: false,
        },
        zones: zones, // definition of the chart colors
      },
    ],
    time: {
      useUTC: false,
    },
    ...DISABLE_CHART_EXPORTING_MENU,
  };

  return (
    <Figure>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </Figure>
  );
};
