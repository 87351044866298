import React from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { emptyRow } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/constants';
import { getFullExchangeName } from '@/helpers/exchangeNames';
import { isObjectWithKey } from '@/helpers/typeGuards';
import { ControlledBaseTextField, EInputValueFormatter } from '@/components/atoms/BaseTextField/BaseTextField';
import {
  StyledAutocomplete,
  StyledPaper,
  StyledMuiTextInputAlignedLeft,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/components/Input.styled';
import { IOption } from '@/types/select';
import { ISingleRowProps } from '@/views/RiskSimulatorPortfolioView/components/Simulator/components/CounterpartyExposures/components/SingleRow/SingleRow';
import { IRiskSimulatorForm } from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';

interface IExchangeInputProps extends Pick<ISingleRowProps, 'formMethods' | 'idx' | 'isCustom'> {
  selectOptions: IOption[];
  onChange: (value: unknown, idx: number) => void;
}

export const ExchangeInput = ({ formMethods, idx, isCustom, selectOptions, onChange }: IExchangeInputProps) => {
  if (isCustom) {
    return (
      <ControlledBaseTextField
        valueFormatter={EInputValueFormatter.NO_FORMAT}
        formMethods={formMethods}
        inputName={`rows.${idx}.entity`}
        placeholder="Custom Exchange"
        helperTextBackgroundColor="#26284b"
        CustomTextInput={StyledMuiTextInputAlignedLeft}
      />
    );
  }

  const handleOptionLabel = (option: unknown) => {
    if (typeof option === 'string') {
      return getFullExchangeName(option);
    }

    if (isObjectWithKey(option, 'label')) {
      return option.label;
    }
  };

  const handleOnChange = (
    newValue: unknown,
    field: ControllerRenderProps<IRiskSimulatorForm, `rows.${number}.entity`>,
  ) => {
    if (isObjectWithKey(newValue, 'value')) {
      field.onChange(newValue.value);
      onChange(newValue.value, idx);
    }

    if (newValue === null) {
      field.onChange(emptyRow.entity);
      onChange(emptyRow.entity, idx);
    }
  };

  return (
    <Controller
      render={({ field, formState: { errors } }) => {
        const isError = isObjectWithKey(errors, 'rows') && !!errors.rows[idx]?.entity;
        return (
          <StyledAutocomplete
            id="exchanges-to-select"
            options={selectOptions}
            getOptionLabel={handleOptionLabel}
            renderInput={(params) => {
              return (
                <StyledMuiTextInputAlignedLeft
                  {...params}
                  placeholder="Select Exchange"
                  error={isError}
                  helperText={isError && errors.rows[idx]?.entity.message}
                />
              );
            }}
            PaperComponent={StyledPaper}
            value={field.value}
            onChange={(event, newValue) => handleOnChange(newValue, field)}
            onBlur={field.onBlur}
          />
        );
      }}
      name={`rows.${idx}.entity`}
      control={formMethods.control}
    />
  );
};
