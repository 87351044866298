import styled from '@emotion/styled';

export const StyledChartsWrapper = styled.div`
  padding: 34px;
  border: 1px solid #2e2e31;
  border-radius: 4px;
`;

export const StyledSpan = styled.span`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4.8px;
  opacity: 0.5;
  text-transform: uppercase;
`;
