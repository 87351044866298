import { EntityDetails } from '@/views/EntityMonitoringView/components/EntityDetails/EntityDetails';
import { AnomaliesTable } from '@/views/EntityMonitoringView/components/AnomaliesTable/AnomaliesTable';
import { ChainDataCharts } from '@/views/EntityMonitoringView/components/ChainDataCharts/ChainDataCharts';
import {
  StyledContainer,
  StyledTextWrapper,
  StyledWrapper,
} from '@/views/EntityMonitoringView/EntityMonitoringView.styled';
import { IGetUserEntityDetailsResponse } from '@/api/userEntities/userEntities.types';
import { TrendingRiskChart } from '@/views/EntityMonitoringView/components/TrendingRiskChart/TrendingRiskChart';

interface IEntityMonitoringViewProps {
  fetchedData: IGetUserEntityDetailsResponse;
}

export const EntityMonitoringView = ({ fetchedData }: IEntityMonitoringViewProps) => {
  const {
    displayName,
    analystNotes,
    isRated,
    balance,
    inflow,
    outflow,
    totalFlow,
    netflow,
    pdYear,
    pdChange,
    actualSeverity,
    riskIndicator,
    latestAnomalies,
    agioId,
    balances,
    netflowData,
    totalFlowData,
    hourlyNetflowData,
    hourlyTotalFlowData,
  } = fetchedData;

  return (
    <StyledContainer>
      <StyledWrapper>
        <EntityDetails
          displayName={displayName}
          isRated={!!isRated}
          balance={balance}
          inflow={inflow}
          outflow={outflow}
          totalFlow={totalFlow}
          netflow={netflow}
          pdYear={pdYear}
          pdChange={pdChange}
          actualSeverity={actualSeverity}
          riskIndicator={riskIndicator}
        />

        {!!analystNotes && <StyledTextWrapper dangerouslySetInnerHTML={{ __html: analystNotes }} />}

        <TrendingRiskChart latestAnomalies={latestAnomalies} />

        <AnomaliesTable latestAnomalies={latestAnomalies} />

        <ChainDataCharts
          agioId={agioId}
          balances={balances}
          netflowData={netflowData}
          totalFlowData={totalFlowData}
          hourlyNetflowData={hourlyNetflowData}
          hourlyTotalFlowData={hourlyTotalFlowData}
        />
      </StyledWrapper>
    </StyledContainer>
  );
};
