export const DISABLE_CHART_EXPORTING_MENU = {
  exporting: {
    enabled: false,
  },
};

export const RESET_ZOOM_BUTTON_POSITION = {
  zooming: {
    type: 'x',
    resetButton: {
      position: {
        align: 'left',
        x: 0,
        y: -40,
      },
    },
  },
};
