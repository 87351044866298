import { subDays, subMonths, format, differenceInCalendarDays } from 'date-fns';

export const getTimeRangeStartDate = (timeRange: string) => {
  const now = new Date();

  switch (timeRange) {
    case 'lastDay':
      return format(subDays(now, 1), "yyyy-MM-dd'T'HH:mm:ss");
    case 'last15Days':
      return format(subDays(now, 15), "yyyy-MM-dd'T'HH:mm:ss");
    case 'last30Days':
      return format(subDays(now, 30), "yyyy-MM-dd'T'HH:mm:ss");
    case 'lastQuarter':
      return format(subMonths(now, 3), "yyyy-MM-dd'T'HH:mm:ss");
    default:
      return '';
  }
};

export const formatEpochToDate = (epoch: number): string => {
  const date = new Date(epoch * 1000);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
  });
  return formattedDate;
};

export const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().substr(-2);

  return `${month}/${day}/${year}`;
};

export const formatToUSD = (amount: number) => {
  const formattedAmount = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);

  return formattedAmount;
};

export const formatNumberToEnUS = (amount: number) => {
  const formattedAmount = amount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return formattedAmount;
};

export const amountToNumber = (amount: string | undefined) => {
  if (!amount) return NaN;

  const number = Number(amount.replace(/[^0-9.-]+/g, ''));
  return number;
};

export const formatDateToUK = (timestamp: Date | string) => {
  try {
    const date = new Date(timestamp);
    return format(date, 'dd/MM/yy');
  } catch (error) {
    console.error('Wrong date:' + timestamp);
    console.error(error);
    return '';
  }
};

export const formatDateToAlert = (timestamp: Date | string) => {
  try {
    const date = new Date(timestamp);
    return format(date, 'LLLL d, yyyy');
  } catch (error) {
    console.error('Wrong date:' + timestamp);
    console.error(error);
    return '';
  }
};

export const formatFloatToPercent = (value: number): string => {
  if (value === null || value === undefined) return '';

  return value.toFixed(2) + '%';
};

export const getListOfNumbers = (length: number, startFromZero = true): number[] =>
  Array.from({ length }, (_, index) => (startFromZero ? index : index + 1));

export const sortAlphabetically = (items: string[]) => {
  return items.sort((a, b) => {
    const nameA = a.toLowerCase();
    const nameB = b.toLowerCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

export const getCalendarDaysDifference = (target: string) => {
  const targetDate = new Date(target);
  const currentDate = new Date();
  const daysDifferenceNumber = differenceInCalendarDays(currentDate, targetDate);

  if (daysDifferenceNumber === 0) {
    return `Today`;
  }

  if (daysDifferenceNumber === 1) {
    return `${daysDifferenceNumber} day ago`;
  }

  return `${daysDifferenceNumber} days ago`;
};

export const capitalizeFirstLetters = (text: string) => {
  return text
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
