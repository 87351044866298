import { mapSeverityChartData } from '@/helpers/mapChartsData';
import { SeverityTrendingChart } from '@/components/molecules/Charts/SeverityTrendingChart';
import {
  StyledChartsWrapper,
  StyledSpan,
} from '@/views/EntityMonitoringView/components/TrendingRiskChart/TrendingRiskChart.styled';
import { IAnomalyDto } from '@/types/anomaly';

interface ITrendingRiskChartProps {
  latestAnomalies: IAnomalyDto[];
}

export const TrendingRiskChart = ({ latestAnomalies }: ITrendingRiskChartProps) => {
  const latestAnomaliesSeverityData = latestAnomalies.map((anomaly) => {
    return {
      timestamp: anomaly.timestamp,
      value: anomaly.severity,
    };
  });

  const chartData = mapSeverityChartData(latestAnomaliesSeverityData);

  return (
    <StyledChartsWrapper>
      <StyledSpan>24h Trending Risk</StyledSpan>
      <SeverityTrendingChart chartData={chartData} />
    </StyledChartsWrapper>
  );
};
