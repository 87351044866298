import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Link, Typography } from '@mui/material';
import Button from '@/components/atoms/Button/Button';
import { EntitiesMonitoring } from '@/views/MonitoringSettingsView/components/EntitiesMonitoring/EntitiesMonitoring';
import { useMonitoringForm } from '@/views/MonitoringSettingsView/utils/useMonitoringForm';
import { StyledContainer, StyledButtonContainer } from '@/views/MonitoringSettingsView/MonitoringSettingsView.styled';

export const MonitoringSettingsView = () => {
  const { monitoringMethods, handleMonitoringFormSubmit, isSettingsLoading } = useMonitoringForm();

  return (
    <FormProvider {...monitoringMethods}>
      <form onSubmit={handleMonitoringFormSubmit}>
        <StyledContainer>
          <EntitiesMonitoring formMethods={monitoringMethods} />
          <StyledButtonContainer>
            <Button
              type="submit"
              variant="contained"
              fitContentWidth
              sx={{ minWidth: '142px' }}
              disabled={isSettingsLoading}
            >
              Save
            </Button>
          </StyledButtonContainer>

          <Typography color="white.100" align="center" fontStyle="italic" pt={10} pl={30} pr={30}>
            If you&apos;re interested in tracking something not on the current list, just{' '}
            <Link href="mailto:alerts@agioratings.io" underline="hover">
              send us
            </Link>{' '}
            a request, and we&apos;ll be happy to assist!
          </Typography>
        </StyledContainer>
      </form>
    </FormProvider>
  );
};
