import { useQuery } from '@tanstack/react-query';
import { useApi } from '@/providers/ApiContextProvider/ApiContextProvider';
import { USER_ENTITIES_KEY } from '@/api/constants/queryKeys';
import { UnknownErrorAndWeCouldNot } from '@/helpers/errors/UnknownErrorAndWeCouldNot';
import { IGetUserEntityDetailsResponse, IMonitoredEntity } from '@/api/userEntities/userEntities.types';

export const useGetUserEntities = () => {
  const { userEntitiesApi } = useApi();

  const {
    data: fetchedUserEntities,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IMonitoredEntity[], Error>({
    queryKey: [USER_ENTITIES_KEY, 'GET'],
    refetchInterval: 3600000, // 1hour
    queryFn: () => {
      return userEntitiesApi
        .getUserEntities()
        .catch(() => Promise.reject(new UnknownErrorAndWeCouldNot(`load user entities data`)));
    },
  });

  return {
    fetchedUserEntities,
    isLoading,
    isSuccess,
    error,
  };
};

export const useGetUserEntityDetails = (entityId: string) => {
  const { userEntitiesApi } = useApi();

  const {
    data: fetchedEntityDetails,
    isLoading,
    isSuccess,
    error,
  } = useQuery<IGetUserEntityDetailsResponse, Error>({
    queryKey: [USER_ENTITIES_KEY, 'GET_ENTITY_DETAILS', entityId],
    meta: { entityId: entityId },
    refetchInterval: 3600000, // 1hour
    queryFn: ({ meta }) => {
      if (meta && meta.entityId && typeof meta.entityId === 'string') {
        return userEntitiesApi
          .getUserEntityDetails(meta.entityId)
          .catch(() => Promise.reject(new UnknownErrorAndWeCouldNot(`load monitored entity details data`)));
      }
      throw new Error('entityId should be a meta string');
    },
  });

  return {
    fetchedEntityDetails,
    isLoading,
    isSuccess,
    error,
  };
};
