import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { defaultMonitoringFormValues } from '@/views/MonitoringSettingsView/utils/constants';
import { useCreateMonitoringSettings, useGetMonitoringSettings } from '@/api/user/user.hooks';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { useTracking } from '@/hooks/useTracking';
import { ERoutes } from '@/types/routes';
import { IMonitoringForm, MonitoringSchema } from '@/views/MonitoringSettingsView/utils/types';
import { zodResolver } from '@hookform/resolvers/zod';

export const useMonitoringForm = () => {
  const navigate = useNavigate();
  const { trackEvent } = useTracking();
  const { exchanges, getExchangeLabelByDisplayName, getDisplayNameByExchangeLabel } = useEntitiesList();

  const monitoringMethods = useForm<IMonitoringForm>({
    defaultValues: defaultMonitoringFormValues,
    resolver: zodResolver(MonitoringSchema),
    mode: 'all',
  });

  const { createMonitoringSettings, isLoading } = useCreateMonitoringSettings();

  const { handleSubmit, setValue } = monitoringMethods;

  const onMonitoringSubmit = useCallback(
    async (data: IMonitoringForm) => {
      const payload = {
        entities: data.sortedEntities.map((e) => getExchangeLabelByDisplayName(e)),
      };

      trackEvent('Monitoring - settings save', {
        entities: payload.entities,
      });

      createMonitoringSettings(payload, {
        onSuccess: () => {
          navigate(ERoutes.MONITORING);
        },
        onError: (error) => {
          console.error('Monitoring settings setup failed: ', error.message);
        },
      });
    },
    [createMonitoringSettings, navigate, exchanges],
  );

  const handleMonitoringFormSubmit = handleSubmit(onMonitoringSubmit);

  const { fetchedSettings, isSuccess } = useGetMonitoringSettings();

  useEffect(() => {
    if (!fetchedSettings || !isSuccess || !exchanges.length) return;

    if (fetchedSettings.entities.length > 0) {
      const fetchedEntities = fetchedSettings.entities.map((e) => getDisplayNameByExchangeLabel(e));
      setValue('entities', fetchedEntities);
      setValue('sortedEntities', fetchedEntities);
    }
  }, [exchanges, fetchedSettings, isSuccess, setValue]);

  return {
    monitoringMethods,
    handleMonitoringFormSubmit,
    isSettingsLoading: isLoading,
  };
};
