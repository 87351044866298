import { useNavigate } from 'react-router-dom';
import { GridEventListener, GridRenderCellParams } from '@mui/x-data-grid';
import { useTracking } from '@/hooks/useTracking';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { mapSeverityChartData } from '@/helpers/mapChartsData';
import ExchangeLogo from '@/components/ExchangeLogo/ExchangeLogo';
import { RiskIndicator } from '@/components/RiskIndicator/RiskIndicator';
import { AlertSeverityBarsIcon } from '@/components/AlertSeverityBarsIcon/AlertSeverityBarsIcon';
import { SeverityTrend } from '@/views/MonitoringView/components/SeverityTrend/SeverityTrend';
import {
  StyledDataGrid,
  StyledLogoWrapper,
} from '@/views/MonitoringView/components/MonitoredEntitiesTable/MonitoredEntitiesTable.styled';
import { IMonitoredEntity } from '@/api/userEntities/userEntities.types';
import { ERoutes } from '@/types/routes';
import { formatToUSD } from '@/helpers/helpers';

interface IMonitoredEntitiesTableProps {
  monitoredEntities: IMonitoredEntity[];
}

export const MonitoredEntitiesTable = ({ monitoredEntities }: IMonitoredEntitiesTableProps) => {
  const { getExchangeLabelByDisplayName } = useEntitiesList();
  const { trackEvent } = useTracking();
  const navigate = useNavigate();

  const mappedEntities = monitoredEntities.map((entity) => {
    const latestAnomaly = entity.latestAnomalies.length ? entity.latestAnomalies[0] : { severity: null };
    const latestBalance = entity.balances?.length ? entity.balances[entity.balances.length - 1].value : null;
    const mappedLatestAnomalies = entity.latestAnomalies.map((anomaly) => {
      return {
        timestamp: anomaly.timestamp,
        value: anomaly.severity,
      };
    });

    const chartData = mapSeverityChartData(mappedLatestAnomalies);

    return {
      ...entity,
      severity: latestAnomaly.severity,
      chartData: chartData,
      latestBalance,
    };
  });

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    const href = params.row.displayName
      ? `${ERoutes.MONITORING}/${getExchangeLabelByDisplayName(params.row.displayName)}`
      : '';

    trackEvent('Monitoring - details click', {
      entity: params.row.displayName || 'unknown',
    });

    navigate(href);
  };

  const columns: any = [
    {
      field: 'displayName',
      headerName: 'Company',
      minWidth: 250,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <StyledLogoWrapper>
            <ExchangeLogo companyName={getExchangeLabelByDisplayName(params.row.displayName)} height="30px" />
            {params.row.displayName}
          </StyledLogoWrapper>
        );
      },
    },
    {
      field: 'severity',
      headerName: 'Severity',
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const severity = params.row.severity;

        if (!severity) return null;

        return <AlertSeverityBarsIcon severity={severity} />;
      },
    },
    {
      field: 'latestBalance',
      headerName: 'Latest Balance',
      minWidth: 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const value = params.row.latestBalance;

        if (!value) return null;

        return <p>{formatToUSD(value)}</p>;
      },
    },
    {
      field: 'riskIndicator',
      headerName: 'Trending Risk',
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <RiskIndicator riskIndicator={params.row.riskIndicator} />;
      },
    },
    {
      field: 'chartData',
      headerName: 'Trending Risk Chart',
      minWidth: 150,
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.chartData.length) return null;

        return <SeverityTrend chartData={params.row.chartData} />;
      },
    },
  ];

  return (
    <StyledDataGrid
      style={{ color: '#fff' }}
      rows={mappedEntities}
      getRowId={(params) => params.agioId}
      onRowClick={handleRowClick}
      columns={columns}
      hideFooter
    />
  );
};
