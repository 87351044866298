import styled from '@emotion/styled';

export const StyledImg = styled.img<{ height?: string }>`
  height: ${({ height }) => (height ? height : '35px')};
`;

export const StyledPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  color: #000;
`;
