import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { assertIsDefined } from '@/helpers/assertions';
import { EntitiesListContext } from '@/providers/EntitiesListContextProvider/EntitiesListContextProvider';
import { useGetUniqueEntitiesList } from '@/api/alerts/alerts.hooks';
import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';
import { useGetUniqueEntitiesListByAdmin } from '@/api/adminAlerts/adminAlerts.hooks';
import { useNewAuth } from '@/providers/AuthProvider/NewAuthProvider.hooks';
import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { sortAlphabetically } from '@/helpers/helpers';

export const useEntitiesListManager = () => {
  const { isAuthenticated } = useNewAuth();
  const { shouldDisplayAdminPanel } = usePermissionsManager();

  const { entitiesList } = useGetUniqueEntitiesList({ isAuthenticated });
  const { entitiesListDisplayedToAdmin } = useGetUniqueEntitiesListByAdmin({
    isAuthenticated,
    isAdmin: shouldDisplayAdminPanel,
  });
  const [exchanges, setExchanges] = useState<IExchange[]>([]);
  const [exchangesDisplayedToAdmin, setExchangesDisplayedToAdmin] = useState<IExchange[]>([]);

  // ________________ENTITIES FOR USER________________

  //IMPORTANT!!! List of exchanges may include exchanges with type as null!
  const listOfEntityTypes = useMemo(() => {
    if (!exchanges.length) return [];

    const availableTypes = Array.from(
      new Set(exchanges.map((exchange) => exchange.type).filter((e): e is string => e !== null)),
    );

    return sortAlphabetically(availableTypes);
  }, [exchanges]);

  const getExchangeLabelByDisplayName = useCallback(
    (displayName: string) => {
      if (exchanges.length === 0) return '';

      const requestedExchange = [...exchanges].find((exchange) => exchange.label === displayName);

      if (requestedExchange) {
        return requestedExchange.value;
      }

      console.error("Didn't find exchange by displayName: ", displayName);
      return '';
    },
    [exchanges],
  );

  const getDisplayNameByExchangeLabel = useCallback(
    (exchangeLabel: string) => {
      if (exchanges.length === 0) return '';

      const requestedExchange = [...exchanges].find((exchange) => exchange.value === exchangeLabel);

      if (requestedExchange) {
        return requestedExchange.label;
      }

      console.error("Didn't find exchange by value: ", exchangeLabel);
      return exchangeLabel;
    },
    [exchanges],
  );

  useEffect(() => {
    if (!entitiesList) return;

    const exchangesList = entitiesList.map(({ agioId, displayName, type }) => ({
      value: agioId,
      label: displayName,
      type: type,
    }));

    exchangesList.sort((a, b) => {
      const nameA = a.label.toLowerCase();
      const nameB = b.label.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });

    setExchanges(exchangesList);
  }, [entitiesList]);

  // ________________ENTITIES FOR ADMIN________________

  useEffect(() => {
    if (!entitiesListDisplayedToAdmin) return;

    const exchangesListDisplayedToAdmin = entitiesListDisplayedToAdmin.map(({ agioId, displayName, type }) => ({
      value: agioId,
      label: displayName,
      type: type,
    }));

    setExchangesDisplayedToAdmin(exchangesListDisplayedToAdmin);
  }, [entitiesListDisplayedToAdmin]);

  return {
    exchanges,
    listOfEntityTypes,
    getExchangeLabelByDisplayName,
    getDisplayNameByExchangeLabel,
    exchangesDisplayedToAdmin,
  };
};

export const useEntitiesList = () => {
  const {
    exchanges,
    listOfEntityTypes,
    getExchangeLabelByDisplayName,
    getDisplayNameByExchangeLabel,
    exchangesDisplayedToAdmin,
  } = useContext(EntitiesListContext);

  assertIsDefined(exchanges, '"IEntitiesListContext.exchanges has to be defined!"');
  assertIsDefined(listOfEntityTypes, '"IEntitiesListContext.listOfEntityTypes has to be defined!"');
  assertIsDefined(
    getExchangeLabelByDisplayName,
    '"IEntitiesListContext.getExchangeLabelByDisplayName has to be defined!"',
  );
  assertIsDefined(
    getDisplayNameByExchangeLabel,
    '"IEntitiesListContext.getDisplayNameByExchangeLabel has to be defined!"',
  );
  assertIsDefined(exchangesDisplayedToAdmin, '"IEntitiesListContext.exchangesDisplayedToAdmin has to be defined!"');

  return {
    exchanges,
    listOfEntityTypes,
    getExchangeLabelByDisplayName,
    getDisplayNameByExchangeLabel,
    exchangesDisplayedToAdmin,
  };
};
