import React from 'react';
import AlertsHeader from '@/components/molecules/Headers/AlertsHeader';
import Sidebar from '@/layouts/MainLayout/components/SideBar/SideBar';
import RatingsHeader from '@/components/molecules/Headers/RatingsHeader';
import EntityRatingsHeader from '@/components/molecules/Headers/EntityRatingsHeader';
import RiskSimulatorHeader from '@/components/molecules/Headers/RiskSimulatorHeader';
import MonitoringHeader from '@/components/molecules/Headers/MonitoringHeader';
import MonitoringSettingsHeader from '@/components/molecules/Headers/MonitoringSettingsHeader';
import { StyledAppContainer, StyledHeader, StyledMain } from '@/layouts/MainLayout/MainLayout.styled';

interface LayoutProps {
  children?: React.ReactNode;
  header?:
    | null
    | 'alerts'
    | 'ratings'
    | 'entityRatings'
    | 'riskSimulator'
    | 'monitoring'
    | 'monitoringSettings'
    | 'entityMonitoring';
}

const MainLayout: React.FC<LayoutProps> = ({ children, header }) => {
  let headerToDisplay = null;
  switch (header) {
    case 'alerts':
      headerToDisplay = <AlertsHeader />;
      break;
    case 'ratings':
      headerToDisplay = <RatingsHeader />;
      break;
    case 'entityRatings':
      headerToDisplay = <EntityRatingsHeader />;
      break;
    case 'riskSimulator':
      headerToDisplay = <RiskSimulatorHeader />;
      break;
    case 'monitoring':
      headerToDisplay = <MonitoringHeader />;
      break;
    case 'monitoringSettings':
      headerToDisplay = <MonitoringSettingsHeader />;
      break;
    case 'entityMonitoring':
      headerToDisplay = <MonitoringHeader isEntityMonitoring />;
      break;
    default:
      headerToDisplay = null;
      break;
  }

  return (
    <StyledAppContainer className="default-layout">
      <Sidebar />
      {headerToDisplay && <StyledHeader>{headerToDisplay}</StyledHeader>}
      <StyledMain className="main">{children} </StyledMain>
    </StyledAppContainer>
  );
};

export default MainLayout;
