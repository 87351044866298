import { usePermissionsManager } from '@/hooks/usePermissionsManager';
import { NoPermissionsView } from '@/views/NoPermissionsView/NoPermissionsView';
import { MonitoringView } from '@/views/MonitoringView/MonitoringView';
import ErrorBoundary from '@/components/Error/ErrorBoundary/ErrorBoundary';
import { CommonError } from '@/components/Error/CommonError/CommonError';

const MonitoringPage = () => {
  const { shouldDisplayAlerts } = usePermissionsManager();

  if (!shouldDisplayAlerts) {
    return <NoPermissionsView />;
  }

  return (
    <ErrorBoundary fallback={<CommonError variant="reload" />}>
      <MonitoringView />
    </ErrorBoundary>
  );
};

export default MonitoringPage;
