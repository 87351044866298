import React, { useMemo } from 'react';
import { Accordion, TextField, Typography } from '@mui/material';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import Checkbox from '@mui/material/Checkbox';
import { Controller, UseFormReturn } from 'react-hook-form';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEntitiesList } from '@/providers/EntitiesListContextProvider/EntitiesListContext.hooks';
import { isArrayOfStrings, isObjectWithKey } from '@/helpers/typeGuards';
import { getFullExchangeTypeName } from '@/helpers/exchangeNames';
import { DraggableEntitiesList } from './components/DraggableEntitiesList/DraggableEntitiesList';
import Button from '@/components/atoms/Button/Button';
import {
  StyledWrapper,
  StyledAutocomplete,
  StyledPaper,
  GroupItems,
  GroupHeader,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from '@/views/MonitoringSettingsView/components/EntitiesMonitoring/EntitiesMonitoring.styled';
import { IMonitoringForm } from '@/views/MonitoringSettingsView/utils/types';
import { IExchange } from '@/providers/EntitiesListContextProvider/EntitiesListContext.types';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IEntitiesMonitoringProps {
  formMethods: UseFormReturn<IMonitoringForm>;
}

export const EntitiesMonitoring = ({ formMethods }: IEntitiesMonitoringProps) => {
  const { exchanges, listOfEntityTypes, getExchangeLabelByDisplayName } = useEntitiesList();

  const { sortedEntities, entities } = formMethods.watch();

  const listOfEntityTypesToDisplay = useMemo(() => {
    return listOfEntityTypes.filter(
      (type) => type !== 'defi' && type !== 'gambling' && type !== 'misc' && type !== 'infrastructure',
    );
  }, [listOfEntityTypes]);

  const sortedExchangesByType = useMemo(() => {
    if (!exchanges.length || !listOfEntityTypesToDisplay.length) return [];

    const sortedBucket: IExchange[] = [];

    listOfEntityTypesToDisplay.forEach((type, i) => {
      exchanges.forEach((exchange) => {
        if (exchange.type === type) {
          sortedBucket.push(exchange);
        }
      });
    });

    return sortedBucket;
  }, [listOfEntityTypesToDisplay, exchanges]);

  const expandedSortedEntities = useMemo(
    () =>
      sortedEntities.map((entity) => {
        return {
          item: entity,
          imageUrl: getExchangeLabelByDisplayName(entity),
        };
      }),
    [exchanges, sortedEntities],
  );

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(sortedEntities, result.source.index, result.destination.index);

    formMethods.setValue('sortedEntities', items);
  };

  const reorder = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateSortEntitiesList = (newValues: unknown) => {
    if (isArrayOfStrings(newValues)) {
      const sortedListLength = sortedEntities.length;
      const newListLength = newValues.length;
      let updatedList;

      if (sortedListLength > newListLength) {
        updatedList = sortedEntities.filter((val) => newValues.includes(val));
      } else {
        const missingEntities = newValues.filter((val) => !sortedEntities.includes(val));
        updatedList = [...sortedEntities, ...missingEntities];
      }

      formMethods.setValue('sortedEntities', updatedList);
    }
  };

  const handleAccordionButtonClick = (exchange: IExchange) => {
    const isAlreadyAdded = entities.some((entity) => entity === exchange.label);

    if (isAlreadyAdded) {
      const filteredOutExchange = entities.filter((entity) => entity !== exchange.label);
      const filteredOutSortedExchange = sortedEntities.filter((entity) => entity !== exchange.label);
      formMethods.setValue('entities', filteredOutExchange);
      formMethods.setValue('sortedEntities', filteredOutSortedExchange);
      return;
    }

    const newExchanges = [...entities, exchange.label];
    const newSortedExchanges = [...sortedEntities, exchange.label];

    formMethods.setValue('entities', newExchanges);
    formMethods.setValue('sortedEntities', newSortedExchanges);
  };

  return (
    <StyledWrapper>
      <Typography variant="h2" color="white.100" fontWeight="semibold">
        Entities to monitor
      </Typography>
      <Controller
        render={({ field, formState: { errors, isValid, isDirty } }) => {
          return (
            <StyledAutocomplete
              multiple
              id="entities-list-to-monitor"
              disableCloseOnSelect
              disablePortal
              options={sortedExchangesByType}
              groupBy={(option) => {
                if (isObjectWithKey(option, 'type')) {
                  return option.type;
                }
              }}
              renderOption={(props, option, { selected }) => {
                if (isObjectWithKey(option, 'label')) {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.label}
                    </li>
                  );
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Entities"
                  error={isDirty && !isValid}
                  helperText={errors.entities?.message}
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{getFullExchangeTypeName(params.group)}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
              PaperComponent={StyledPaper}
              value={field.value}
              isOptionEqualToValue={(option, value) => {
                if (isObjectWithKey(option, 'label')) {
                  return option.label === value;
                }
                return false;
              }}
              onChange={(event, newValues) => {
                if (Array.isArray(newValues)) {
                  const extractedNewValues = [...newValues].map((val) => {
                    if (typeof val === 'string') {
                      return val;
                    }
                    return val.label;
                  });
                  field.onChange(extractedNewValues);
                  updateSortEntitiesList(extractedNewValues);
                }
              }}
              onBlur={field.onBlur}
            />
          );
        }}
        name="entities"
        control={formMethods.control}
      />

      <div>
        {listOfEntityTypesToDisplay.map((type) => {
          return (
            <Accordion key={type}>
              <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: '#fff' }} />}
                aria-controls={`${type}-content`}
                id={`${type}-header`}
              >
                {getFullExchangeTypeName(type).toUpperCase()}
              </StyledAccordionSummary>
              <StyledAccordionDetails>
                {sortedExchangesByType.map((exchange) => {
                  const isSelected = entities.some((entity) => entity === exchange.label);

                  if (exchange.type === type) {
                    return (
                      <Button
                        key={`${exchange.value}-${exchange.type}`}
                        variant={isSelected ? 'contained' : 'outlined'}
                        fitContentWidth
                        onClick={() => {
                          handleAccordionButtonClick(exchange);
                        }}
                      >
                        {exchange.label}
                      </Button>
                    );
                  }
                })}
              </StyledAccordionDetails>
            </Accordion>
          );
        })}
      </div>

      {!!sortedEntities.length && (
        <>
          <Typography variant="h2" color="white.100" fontWeight="semibold">
            The order of entities to monitor
          </Typography>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <DraggableEntitiesList
                  provided={provided}
                  snapshot={snapshot}
                  sortedEntities={expandedSortedEntities}
                />
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </StyledWrapper>
  );
};
