import Loader from '@/components/atoms/Loader/Loader';
import { GeneratedResultView } from '@/views/RiskSimulatorPortfolioView/components/ResultOfRiskSimulation/components/GeneratedResultView/GeneratedResultView';
import {
  IParametersOfSimulation,
  IResultOfRiskSimulation,
} from '@/views/RiskSimulatorPortfolioView/components/Simulator/utils/types';

interface IResultOfRiskSimulationProps {
  isSimulationLoading: boolean;
  resultOfRiskSimulation: IResultOfRiskSimulation | null;
  parametersOfDisplayedSimulation: IParametersOfSimulation | null;
}

export const ResultOfRiskSimulation = ({
  isSimulationLoading,
  resultOfRiskSimulation,
  parametersOfDisplayedSimulation,
}: IResultOfRiskSimulationProps) => {
  if (isSimulationLoading) return <Loader />;

  if (resultOfRiskSimulation !== null && parametersOfDisplayedSimulation !== null)
    return (
      <GeneratedResultView
        resultOfRiskSimulation={resultOfRiskSimulation}
        parametersOfDisplayedSimulation={parametersOfDisplayedSimulation}
      />
    );

  return null;
};
