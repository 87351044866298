import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTracking } from '@/hooks/useTracking';
import { useGetUserEntities } from '@/api/userEntities/userEntities.hooks';
import { MonitoredEntitiesTable } from '@/views/MonitoringView/components/MonitoredEntitiesTable/MonitoredEntitiesTable';
import Loader from '@/components/atoms/Loader/Loader';
import {
  StyledContainer,
  StyledLoaderContainer,
  StyledButton,
  StyledLink,
} from '@/views/MonitoringView/MonitoringView.styled';
import { ERoutes } from '@/types/routes';

export const MonitoringView = () => {
  const { fetchedUserEntities, isLoading, error } = useGetUserEntities();
  const { trackEvent } = useTracking();

  if (isLoading) {
    return (
      <StyledLoaderContainer>
        <Loader />
      </StyledLoaderContainer>
    );
  }

  if (error || !fetchedUserEntities) {
    throw error;
  }

  return (
    <StyledContainer>
      <MonitoredEntitiesTable monitoredEntities={fetchedUserEntities} />
      <StyledLink
        href={ERoutes.MONITORING_SETTINGS}
        underline="none"
        onClick={() => trackEvent('Monitoring - click settings', {})}
      >
        <StyledButton variant="outlined" color="tertiary" skipTypography fitContentWidth>
          <AddCircleOutlineIcon />
          Add entity
        </StyledButton>
      </StyledLink>
    </StyledContainer>
  );
};
