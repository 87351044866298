import { SeverityLevelKey, TSeverity } from '@/types/severityLevel';

export const adjustSeverityLevel = (zScore: number): SeverityLevelKey => {
  switch (true) {
    case zScore < -8.5:
      return 'extreme';
    case zScore < -4.2 && zScore >= -8.5:
      return 'high';
    case zScore < -2.5 && zScore >= -4.2:
      return 'medium';
    case zScore <= -1.5 && zScore >= -2.5:
      return 'low';
    default:
      return 'none';
  }
};

export const adjustSeverityColor = (severity: SeverityLevelKey): string => {
  switch (severity) {
    case 'extreme':
      return '#C00000';
    case 'high':
      return '#F60102';
    case 'medium':
      return '#FAC002';
    case 'low':
      return '#FEFF01';
    default:
      return '#4EA72E';
  }
};

export const adjustSeverityChartPoint = (severity: TSeverity): number | null => {
  switch (severity) {
    case 'extreme':
      return 4;
    case 'high':
      return 3;
    case 'medium':
      return 2;
    case 'low':
      return 1;
    case 'none':
      return 0;
    default:
      return null;
  }
};

export const adjustSeverityChartLabel = (severity: number | null): SeverityLevelKey | string => {
  switch (severity) {
    case 4:
      return 'extreme';
    case 3:
      return 'high';
    case 2:
      return 'medium';
    case 1:
      return 'low';
    case 0:
      return 'none';
    default:
      return '';
  }
};

export const adjustSeverityChartColor = (severity: number): string => {
  switch (severity) {
    case 4:
      return '#C00000';
    case 3:
      return '#F60102';
    case 2:
      return '#FAC002';
    case 1:
      return '#FEFF01';
    default:
      return '#4EA72E';
  }
};
