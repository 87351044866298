import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { StyledImg, StyledPlaceholder } from '@/components/ExchangeLogo/ExchangeLogo.styled';

interface ExchangeLogoProps {
  companyName: string;
  height?: string;
  placeholderName?: string | null;
}

const ExchangeLogo = ({ companyName, height, placeholderName = 'Logo' }: ExchangeLogoProps) => {
  const [imageLoaded, setImageLoaded] = useState(true);
  const imagePath = `https://cdn.agioratings.io/icons/${companyName.replace('.', '_').replace('-', '_')}.png`;

  useEffect(() => {
    setImageLoaded(true);
  }, [companyName]);

  const handleError = () => {
    console.warn(`Missing logo: ${companyName}`);
    setImageLoaded(false);
  };

  return (
    <>
      {imageLoaded && <StyledImg src={imagePath} alt={companyName} onError={handleError} height={height} />}

      {!imageLoaded && (
        <StyledPlaceholder>
          <Typography fontStyle="14px" noWrap>
            {placeholderName}
          </Typography>
        </StyledPlaceholder>
      )}
    </>
  );
};

export default ExchangeLogo;
